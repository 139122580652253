
// Vue reactivity
import { computed, } from "vue";

// icons
import { } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonTitle, IonContent, IonGrid, IonToolbar,
        IonButtons, IonButton, IonBackButton, IonRow, IonCol, IonSpinner, } from "@ionic/vue";
import ProductCard from "@/components/product/ProductCard.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: "HomeSectionPage",
  components: {
    IonPage, IonHeader, IonTitle, IonContent, IonGrid, IonToolbar,
    IonButtons, IonButton, IonBackButton, IonRow, IonCol, IonSpinner,
    ProductCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const currId = route.params.id;

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const section = computed(() => store.getters.getHomeSectionById(currId));
    const sectionProducts = computed(() => store.getters.getProductsBySectionId(currId));

    // methods or filters
    const { t } = useI18n();
    const { getLocalizedStr, } = utils();
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons

      // state variables
      loading, userLoggedIn,
      section, sectionProducts,

      // methods
      t, getLocalizedStr,
    };
  },
};
